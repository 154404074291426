/* new css code */
:root {
  --color-primary: #16aae0;
  --color-secondary: #17558a;
  --color-tertiary: #f0f0f0;

  --color-primary-light: #ffffff;
  --color-secondary-light: #d0d0d0;
  --color-tertiary-light: #a5a5a5;

  --color-primary-dark: #000000;
  --color-secondary-dark: #111111;
  --color-tertiary-dark: #828282;

  --color-primary-transparent: rgba(22, 170, 224, 0.5);
  --color-secondary-transparent: rgba(23, 85, 138, 0.5);
  --color-tertiary-transparent: rgba(240, 240, 240, 0.5);

  --color-primary-transparent-light: rgba(255, 255, 255, 0.5);
  --color-secondary-transparent-light: rgba(208, 208, 208, 0.5);
  --color-tertiary-transparent-light: rgba(165, 165, 165, 0.5);

  --color-primary-transparent-dark: rgba(0, 0, 0, 0.5);
  --color-secondary-transparent-dark: rgba(17, 17, 17, 0.5);
  --color-tertiary-transparent-dark: rgba(130, 130, 130, 0.5);

  --font-family-primary: "Be Vietnam Pro", sans-serif;
  --font-family-secondary: "Montserrat", sans-serif;
  --font-family-tertiary: "Mukta", sans-serif;
  --font-family-quaternary: "Ubuntu", sans-serif;

  --font-family-body: var(--font-family-primary);
  --font-family-heading: var(--font-family-primary);
  --font-family-label: var(--font-family-primary);

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-size-xxs: 0.625rem;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-xxxl: 1.75rem;

  --font-size-xxxxl: 1.875rem;
  --font-size-h3: 2.5rem;
  --font-size-h2: 3.5rem;

  --font-body: var(--font-size-sm);
  --font-primary-heading: var(--font-size-h3);
  --font-secondary-heading: var(--font-size-xxxxl);
  --font-label: var(--font-size-md);
  --font-size-nav: var(--font-size-md);

  --animation-duration: 0.3s;
  --animation-duration-long: 0.5s;
}

/* @media (max-width: 550px) {
  .menu-button-text {
    font-size: 2em;
    color: firebrick;
  }
} */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
* {
  padding: 0;
  margin: 0;
  font: inherit;
}

/* Remove default list styles on ul, ol elements with a list role, which suggests default styling will be removed */

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

/* Set core body defaults */
body {
  /* text-rendering: optimizeSpeed;
  line-height: 1.5; */
  margin: 0;
  max-width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
  background-color: #fff;
  font-family: "Mukta", sans-serif;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
}

/* *,
*::before,
*::after {
  animation-duration: 0.5ms !important;
  animation-iteration-count: 1 !important;
  transition-duration: 0.3ms !important;
  scroll-behavior: auto !important;
} */

.text-primary {
  color: var(--color-primary);
}

.text-secondary {
  color: var(--color-secondary);
}

.text-tertiary {
  color: var(--color-tertiary);
}

.text-primary-light {
  color: var(--color-primary-light);
}

.text-secondary-light {
  color: var(--color-secondary-light);
}

.text-tertiary-light {
  color: var(--color-tertiary-light);
}

.text-primary-dark {
  color: var(--color-primary-dark);
}

.text-secondary-dark {
  color: var(--color-secondary-dark);
}

.text-tertiary-dark {
  color: var(--color-tertiary-dark);
}

.text-primary-transparent {
  color: var(--color-primary-transparent);
}

.text-secondary-transparent {
  color: var(--color-secondary-transparent);
}

.text-tertiary-transparent {
  color: var(--color-tertiary-transparent);
}

.text-primary-transparent-light {
  color: var(--color-primary-transparent-light);
}

.text-secondary-transparent-light {
  color: var(--color-secondary-transparent-light);
}

.text-tertiary-transparent-light {
  color: var(--color-tertiary-transparent-light);
}

.text-primary-transparent-dark {
  color: var(--color-primary-transparent-dark);
}

.text-secondary-transparent-dark {
  color: var(--color-secondary-transparent-dark);
}

.text-tertiary-transparent-dark {
  color: var(--color-tertiary-transparent-dark);
}

.bg-primary {
  background-color: var(--color-primary);
}

.bg-secondary {
  background-color: var(--color-secondary);
}

.bg-tertiary {
  background-color: var(--color-tertiary);
}

.bg-primary-light {
  background-color: var(--color-primary-light);
}

.bg-secondary-light {
  background-color: var(--color-secondary-light);
}

.bg-tertiary-light {
  background-color: var(--color-tertiary-light);
}

.bg-primary-dark {
  background-color: var(--color-primary-dark);
}

.bg-secondary-dark {
  background-color: var(--color-secondary-dark);
}

.bg-tertiary-dark {
  background-color: var(--color-tertiary-dark);
}

.bg-primary-transparent {
  background-color: var(--color-primary-transparent);
}

.bg-secondary-transparent {
  background-color: var(--color-secondary-transparent);
}

.bg-tertiary-transparent {
  background-color: var(--color-tertiary-transparent);
}

.bg-primary-transparent-light {
  background-color: var(--color-primary-transparent-light);
}

.bg-secondary-transparent-light {
  background-color: var(--color-secondary-transparent-light);
}

.bg-tertiary-transparent-light {
  background-color: var(--color-tertiary-transparent-light);
}

.bg-primary-transparent-dark {
  background-color: var(--color-primary-transparent-dark);
}

.bg-secondary-transparent-dark {
  background-color: var(--color-secondary-transparent-dark);
}

.bg-tertiary-transparent-dark {
  background-color: var(--color-tertiary-transparent-dark);
}

.font-body {
  font-size: var(--font-body);
}

.font-primary-heading {
  font-size: var(--font-primary-heading);
}

.font-secondary-heading {
  font-size: var(--font-secondary-heading);
}

.font-label {
  font-size: var(--font-label);
}

.fs-nav {
  font-size: var(--font-size-nav);
}

.fs-xxs {
  font-size: var(--font-size-xxs);
}

.fs-xs {
  font-size: var(--font-size-xs);
}

.fs-sm {
  font-size: var(--font-size-sm);
}

.fs-md {
  font-size: var(--font-size-md);
}

.fs-xxxl {
  font-size: var(--font-size-xxxl);
}

.fs-xxxxl {
  font-size: var(--font-size-xxxxl);
}

.fs-h3 {
  font-size: var(--font-size-h3);
}

.fs-h2 {
  font-size: var(--font-size-h2);
}

.fw-light {
  font-weight: var(--font-weight-light);
}

.fw-normal {
  font-weight: var(--font-weight-normal);
}

.fw-medium {
  font-weight: var(--font-weight-medium);
}

.fw-bold {
  font-weight: var(--font-weight-bold);
}

/* old css code  */

/* body {
  margin: 0;
  max-width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
  background-color: #fff;
  font-family: "Mukta", sans-serif;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.my-facebook-button-class {
  background-color: #3b5998;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  width: 100%;
}

/* .app-container {
  padding-right: 15px;
} */

.text-center {
  text-align: center;
}

.text-title {
  font-size: 1.5em;
  font-weight: 500;
  color: black;
  margin-bottom: 0.5em;
  padding: 0.5em 0;
  font-family: "Ubuntu", sans-serif;
}

.big-text {
  font-size: 1.2em;
  font-weight: 500;
  color: var(--color-primary-dark);

  font-family: var(--font-family-primary);
}

.blue {
  color: #3b5998;
}

.title-text {
  font-size: 1.5em;
  font-weight: 600;
  color: #111;
  margin-bottom: 0.5em;
  margin-top: 0;
  padding-top: 0;
  font-family: "Montserrat", sans-serif;
}

.footer-title {
  font-size: 1rem;
  font-weight: 500;
  color: #16aae0;
  font-family: "Ubuntu", sans-serif;
}

.text-subtitle {
  font-size: 1em;
  font-weight: 400;
  color: grey;
  font-family: "Mukta", sans-serif;
}

.text-subtitle2 {
  font-size: 0.8em;
  font-weight: 600;
  color: black;
  font-family: "Montserrat", sans-serif;
}
.text-muted {
  font-size: 0.8em;
  font-weight: 400;
  color: grey;
  font-family: "Mukta", sans-serif;
}
.text-body {
  font-size: 1em;
  font-weight: 500;
  color: black;
  font-family: "Montserrat", sans-serif;
}

.text-body-light {
  font-size: 1em;
  font-weight: 500;
  color: white;
  font-family: "Mukta", sans-serif;
}

.text-body-red {
  font-size: 1em;
  font-weight: 400;
  color: red;
  font-family: var(--font-family-primary);
}

.orange {
  color: #f57c00;
}

.text-caption {
  font-size: 0.8em;
  font-weight: 400;
  color: grey;
  font-family: "Mukta", sans-serif;
}

.text-caption2 {
  font-size: 0.77em;
  font-weight: 500;
  color: black;
  font-family: "Montserrat", sans-serif;
}

.footer__content__text {
  font-size: 1em;
  font-weight: 400;
  color: white;
  font-family: "Mukta", sans-serif;
}

.text-body-bold {
  font-size: 1em;
  font-weight: 700;
  color: black;
  font-family: "Mukta", sans-serif;
}

.footer__content__link__title {
  font-size: 1em;
  font-weight: 500;
  color: white;
  font-family: "Mukta", sans-serif;
  cursor: pointer;
}

.footer__content__link__title:hover {
  color: #16aae0;
}

.footer__bottom__text {
  font-size: 1em;
  font-weight: 400;
  color: white;
  font-family: "Mukta", sans-serif;
}

.footer__bottom__text a {
  color: #16aae0;
  text-decoration: none;
}

.text-body-italic {
  font-size: 1em;
  font-weight: 400;
  color: black;
  margin-bottom: 1em;
  font-family: "Mukta", sans-serif;
  font-style: italic;
}

.text-body-underline {
  font-size: 1em;
  font-weight: 400;
  color: black;
  margin-bottom: 1em;
  font-family: "Mukta", sans-serif;
  text-decoration: underline;
}

.text-body-strikethrough {
  font-size: 1em;
  font-weight: 400;
  color: black;
  margin-bottom: 1em;
  font-family: "Mukta", sans-serif;
  text-decoration: line-through;
}

.text-body-link {
  font-size: 1em;
  font-weight: 400;
  color: black;
  margin-bottom: 1em;
  font-family: "Mukta", sans-serif;
  text-decoration: underline;
}

.text-body-link:hover {
  color: #3b5998;
}

.text-body-link:active {
  color: #3b5998;
}

.text-body-link:visited {
  color: #3b5998;
}

.text-paragraph-dark {
  font-size: 1em;
  font-weight: 400;
  color: #333;
  margin-bottom: 1em;

  font-family: "Mukta", sans-serif;
}

/* MENU DROP DOWN STYLES */

.menu-dropdown {
  left: 0;
  max-height: 80vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
  background-color: var(--color-primary-light);
  z-index: 100;
  padding-bottom: 30px;
  animation-name: change-height;
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  /* animation-fill-mode: forwards; */
}

.menu-dropdown--mobile {
  left: 0;
  max-height: 80vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
  background-color: var(--color-primary);
  z-index: 100;
  padding-bottom: 30px;
  animation-name: change-height;
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  /* animation-fill-mode: forwards; */
}

@keyframes change-height {
  0% {
    max-height: 0;
    overflow-y: hidden;
  }
  100% {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.side-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-self: start;
  align-items: flex-start;

  width: 100vw;
  height: 90vh;
  overflow-y: auto;
  gap: 3px;
  z-index: 999;
  top: 55px;
  left: 0;
  background-color: #fff;
  animation-name: slide-in;
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.mobile-search-container {
  display: "flex";
  align-items: center;
  margin-right: 5px;
  animation-name: slide-in-from-left;
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slide-in-from-left {
  0% {
    transform: translate(200%);
  }

  100% {
    transform: 0;
  }
}

@keyframes size-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.dropdown-link-text {
  font-size: 1em;
  font-weight: 400;
  color: var(--color-primary-dark);
  font-family: var(--font-family-primary);
  text-decoration: none;
  transition: 0.3s ease-in-out all;
}

.dropdown-link-text:hover {
  scale: 1.2;
}

.menu-button-text {
  font-size: 1em;
  font-weight: 00;
  color: #333;
  font-family: var(--font-family-heading);
  text-decoration: none;
  transition: 0.3s ease-in-out all;
}

.menu-button-text:hover {
  scale: 1.05;
}

.order-button-text {
  font-size: 1em;
  font-weight: 600;
  color: #333;
  font-family: "Mukta", sans-serif;
  text-decoration: none;
  transition: 0.3s ease-in-out all;
}

.order-button-text:hover {
  scale: 1.1;
}

.order-by-buttons-text {
  font-size: 1em;
  font-weight: 600;
  color: #333;
  font-family: "Ubuntu", sans-serif;
  text-decoration: none;
  transition: 0.3s ease-in-out all;
}

.last_child_contian {
  margin: 0px;
  padding: 0;
  font-family: var(--font-family-heading);
}

.error_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (min-width: 576px) {
  :root {
    --font-size-h3: 3.5rem;
    --font-size-xxxl: 2.5rem;
    --font-nav: var(--font-size-sm);
    --font-primary-heading: var(--font-size-h2);
    --font-secondary-heading: var(--font-size-h3);
  }
}

@media (max-width: 768px) {
  .menu-button-text {
    font-size: 1rem;
    font-weight: var(--font-weight-bold);
  }
  .last_child_contian {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    justify-content: start;
    width: 100%;
    margin: 0px;
  }
  .big-text {
    font-size: 1.1rem;
    font-weight: var(--font-weight-medium);
    color: #222;
  }
}

.promotion-text {
  font-size: 1.1rem;
  font-weight: var(--font-weight-medium);
  color: #222;
  /* animation-name:  */
}

.cart__container {
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  z-index: 9999;
  animation-name: slide-in-from-right;
  animation-duration: var(--animation-duration-long);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slide-in-from-right {
  0% {
    transform: translate(200%);
  }

  100% {
    transform: 0;
  }
}

@media (max-width: 768px) {
  .cart__container {
    width: 100%;
  }
}
