@media screen and (min-width: 800px) {
  .swiper {
    width: 100%;
    height: 100% !important;
  }
}

/* 
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100% !important;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100% !important;
  background-color: transparent !important;
  position: relative;
  transition-property: transform;
}
 */
.swiper-slide img {
  display: block;
  width: 100% !important;
  object-fit: cover;
  background-size: contain;
  margin: auto;
}
